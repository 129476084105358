import React from "react";
import ReactDOM from "react-dom/client";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import App from "./App";

// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAr5JQIgBFeaFEJMOHvAIgzAM_GWGNe14o",
  authDomain: "thegoldapple-main-site.firebaseapp.com",
  projectId: "thegoldapple-main-site",
  storageBucket: "thegoldapple-main-site.appspot.com",
  messagingSenderId: "735072593987",
  appId: "1:735072593987:web:0b07005a38c9884ffbccf6",
  measurementId: "G-ZHBXSVQ5F3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, "Site Reached");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);