//import logo from './logo.svg';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/home.js'
// import Vacencies from './pages/vacencies.js';
import MC from './pages/MC.js';
import Pack from './pages/Pack.js';

function App(args) {

  return (
    <Router>
      <Routes>
        <Route exact path='/' element={ <Home /> } />
        <Route path='/mc/pack' element={ <Pack /> } />
        <Route path='/mc' element={ <MC /> } />
        {/* <Route path='/vacencies' element={ <Vacencies /> } /> */}
      </Routes>
    </Router>
  );
}

export default App;
